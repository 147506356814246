import { SessionProtocols } from '@bridge/types/SessionTypes';
import { IAllocateResourceSuccessResponse } from '@core/wsbroker/types';

/* RTCHandler callback function types */
export type OnSuccessRTCCallback = (payload: any) => void | Promise<void>;

/* RTC Message Types */
export interface RTCChannelMessage {
  Version: string;
  Action: Action;
  ActionType: ActionType;
  Payload: any;
}

// ToDo: Remove State_Change action since its not required
export enum Action {
  GET_PLATFORM_DETAILS = 'GET_PLATFORM_DETAILS',
  STATE_CHANGE = 'STATE_CHANGE',
  AUTH_CONTEXT = 'AUTH_CONTEXT',
  STREAM_CONTEXT = 'STREAM_CONTEXT',
  STREAM = 'STREAM',
  RECONNECT = 'RECONNECT',
  MANAGE_WORKSPACE = 'MANAGE_WORKSPACE',
  PUBLISH_METRIC = 'PUBLISH_METRIC',
  PUBLISH_LOG = 'PUBLISH_LOG',
  TRIGGER_LOG_UPLOAD = 'TRIGGER_LOG_UPLOAD',
  LOG_UPLOAD_PUBLISH = 'LOG_UPLOAD_PUBLISH',
  SETTINGS_UPDATE = 'SETTINGS_UPDATE',
  DISCONNECT = 'DISCONNECT',
  HEALTH_CHECK = 'HEALTH_CHECK',
  RESOLVE_DNS_RECORD = 'RESOLVE_DNS_RECORD',
  MIGRATE_REGISTRATION_CODES = 'MIGRATE_REGISTRATION_CODES',
  LOG_UPLOAD_CHECK_ELIGIBILITY = 'LOG_UPLOAD_CHECK_ELIGIBILITY',
  DISPLAY_WARPDRIVE_URL = 'DISPLAY_WARPDRIVE_URL',
  BROKER_AUTHENTICATION_RESULT = 'BROKER_AUTHENTICATION_RESULT',
}

export enum ActionType {
  REQUEST = 'REQUEST',
  RESPONSE = 'RESPONSE',
}

export interface ActionFailure {
  ExceptionType: string;
  ErrorCode: string;
  ErrorMessage: string;
}

/* Negotiation Types */
export interface GetPlatformDetailsRequest {
  UserSettings: UserSettings;
}

export interface GetPlatformDetailsResponse {
  Version: string;
  DeviceId: string;
  VendorName: string;
  ModelNumber: string;
  Platform: PlatformType;
  PlatformVersion: string;
  ProtocolVersion: string;
  IsCanaryRun: boolean;
  AuthCapabilities: AuthCapability[];
  PlatformSettings: PlatformSettings;
  IsOemHandshaked: boolean;
}

export enum PlatformType {
  LINUX = 'Linux',
  WINDOWS = 'Windows',
  IOS = 'IOS',
  MACOS = 'MacOS',
  WEB = 'Web',
  THINCLIENT = 'ThinClient',
}

export interface AuthCapability {
  AuthType: AuthType;
  AuthStrategy: AuthStrategy;
}

export enum AuthType {
  WARP_DRIVE = 'WARP_DRIVE',
  SSO = 'SSO',
  DEVICE_AUTH = 'DEVICE_AUTH',
  FIPS = 'FIPS',
  SAML = 'SAML_IAM_AUTH_PROVIDER',
  IDC = 'IDC_AUTH_PROVIDER',
}

export enum AuthStrategy {
  CREDENTIAL = 'CREDENTIAL',
  SMART_CARD = 'SMART_CARD',
  MFA = 'MFA',
  SAML = 'SAML',
  IDC = 'IDC',
  SEAMLESS = 'SEAMLESS',
  SEAMLESS_WITH_FALLBACK = 'SEAMLESS_WITH_FALLBACK',
  CERTIFICATE = 'CERTIFICATE',
  CREDENTIAL_BROKER_PROTOCOL_V2 = 'CREDENTIAL_BROKER_PROTOCOL_V2',
}

export interface PlatformSettings {
  ProxyType: ProxyType;
  SupportedProtocols: SessionProtocols[];
  IsSupportIPv4Fallback: boolean;
}

export enum ProxyType {
  NoProxy = 'NoProxy',
  SystemProxy = 'SystemProxy',
  CustomProxy = 'CustomProxy',
}

/* Session StateChange Update types */
export enum SessionState {
  NEGOTIATION = 'NEGOTIATION',
  REGISTRATION = 'REGISTRATION',
  HELLO = 'HELLO',
  CUSTOMIZATION = 'CUSTOMIZATION',
  WARP_DRIVE_REDIRECTION_START = 'WARP_DRIVE_REDIRECTION_START',
  WARP_DRIVE_REDIRECTION_COMPLETE = 'WARP_DRIVE_REDIRECTION_COMPLETE',
  SAML_IAM_REDIRECTION_START = 'SAML_IAM_REDIRECTION_START',
  IDC_IAM_REDIRECTION_START = 'IDC_IAM_REDIRECTION_START',
  AUTHENTICATE = 'AUTHENTICATE',
  GET_RESOURCE = 'GET_RESOURCE',
  ALLOCATE_RESOURCE = 'ALLOCATE_RESOURCE',
  CONNECT = 'CONNECT',
  RESUME = 'RESUME',
  RESUME_PENDING = 'RESUME_PENDING',
  STREAMING = 'STREAMING',
  DISCONNECT = 'DISCONNECT',

  /* RTCChannel SessionStates */
  STREAM_CONTEXT_RECEIVED = 'STREAM_CONTEXT_RECEIVED',
}

// Web Client --> Solo
export interface StateChangeUpdate {
  State: SessionState;
  StateConfig: CustomizationConfig | ExternalAuthRedirectConfig;
}

export interface CustomizationConfig {
  EncodedCSSDecorator?: string;
  CustomerBranding: {
    ForgotPasswordLink?: string;
  };
}

export interface ExternalAuthRedirectConfig {
  AuthRedirectUrl: string;
  FailedRedirectFallbackUrl: string;
}

export enum ExternalAuthRedirectErrorCodes {
  AuthPortalLoadTimeOut = 'AuthPortalLoadTimeOut',
  AuthPortalUnavailable = 'AuthPortalUnavailable',
  AuthPortalExternalRedirectError = 'AuthPortalExternalRedirectError',
  AuthPortalUnknownError = 'AuthPortalUnknownError',
}

/* StreamContext Update types */
export interface StreamContextRequest {
  Protocol: SessionProtocols;
  DomainName: string;
  AllocatedResource: IAllocateResourceSuccessResponse; // RawResponse returned from AllocateResource
  Blob?: any;
}

export interface StreamContextResponse {
  StreamAttributes: StreamAttributes;
}

export interface StreamAttributes {
  AuthBlob?: string;
}

/* Native Streaming types */
export interface StreamRequest {
  Protocol: SessionProtocols;
  StreamInputs: string;
}

/* Disconnect types */
export interface DisconnectRequest {
  DisconnectCode: string;
}

/* Logging Types */
export interface LogMessage {
  Message: string;
  LogLevel: string;
}

/* Log Uploader Types */
export interface LogUploadDetails {
  ClientLogFiles: string;
  ProtocolLogFiles: string;
}

export interface EligibleLogFileInfo {
  EligibleLogFileName: string;
}

export interface LogUploadData {
  LogUploadFileName: string;
  LogUploadData: Uint8Array;
}

/* Metric Types */
export interface MetricMessage {
  Operation: string;
  MetricResult: string;
  MetricName: string;
  Value: number;
  Dimensions: Map<string, string>;
  Exception: {
    ErrorMessage: string;
    ErrorType: string;
    ErrorCode: string;
    ErrorReason: string;
    StackTrace?: string;
  };
}

/* AuthContext Types */
export enum AuthContextRequestType {
  ReconnectionContext = 'ReconnectionContext',
  SignCertificate = 'SignCertificate',
}

export interface AuthContextInputs {
  SessionProtocol: SessionProtocols;
}

export interface AuthContextRequest {
  AuthContextRequestType: AuthContextRequestType;
  AuthContextInputs: AuthContextInputs;
}

// ToDo: when we switch to using Model pkg, restrict userName, passwd to specific type
export interface AuthContextResponse {
  UserName: string;
  Password: string;
}

/* SettingsUpdate Types */
export interface SettingsUpdateRequest {
  UserSettings: UserSettings;
}

export interface UserSettings {
  Locale?: LanguageOptions;
  RegistrationCode?: string;
  MetricEndpoint?: string;
  RegionEndpoint?: string;
  WebClientEndpoint?: string;
  MetricIPv6Endpoint?: string;
}

export enum LanguageOptions {
  EN_US = 'en',
  FR_FR = 'fr',
  DE_DE = 'de',
  ES_ES = 'es',
  JA_JP = 'ja',
  KO_KR = 'ko',
  PT_BR = 'pt_BR',
  ZH_CN = 'zh_CN',
}

/* HealthCheck Types */

export interface NetworkConfig {
  Protocol: String;
  Ports: number[];
}

export interface HealthCheckRequest {
  Hostname: String;
  NetworkConfig: NetworkConfig[];
}

export interface NetworkConnectivityResult {
  Port: number;
  IsHealthy: boolean;
}

export interface HealthCheckResponse {
  TcpConnectivity: NetworkConnectivityResult[];
  UdpConnectivity: NetworkConnectivityResult[];
  TcpRoundTrip: number;
  UdpRoundTrip: number;
}

/* RESOLVE_DNS_DOMAIN types */

// We only need TXT records for resolving region for connection alias in CRR feature.
export enum DNSRecordType {
  ALL = 'ALL',
  TXT = 'TXT',
}

export interface ResolveDNSDomainRequest {
  Domain: string;
  RecordType: DNSRecordType;
}

export interface ResolveDNSDomainResponse {
  Domain: string;
  RecordType: DNSRecordType;
  RawRecords: string[];
}

export interface MigrateRegistrationCodesResponse {
  RegistrationCodes: RegistrationCode[];
}

export interface RegistrationCode {
  Name: string;
  Alias: string;
  RegionKey: string;
  IsRememberMeLocalSetting: boolean;
  IsRememberMeAdminSetting: boolean;
}

export interface DisplayWarpDriveURL {
  URL: string;
}

export interface BrokerAuthenticationResult {
  IsAuthenticated: boolean;
}
